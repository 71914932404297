import { Component, OnInit, Input } from "@angular/core";
import { AppComponent } from "../../app/app.component";

@Component({
  selector: "app-comparison-bmi",
  templateUrl: "./comparison-bmi.component.html",
  styleUrls: ["./comparison-bmi.component.css"],
})
export class ComparisonBmiComponent implements OnInit {
  @Input() data: any = {};

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: ["iprobio_blue", "iprobio_blue_light", "iprobio_green", "iprobio_red", "iprobio_blue_dark"],
  }
  legendColors: any = this.legendColorsVariants['default'];
  constructor(public app: AppComponent) {}

  ngOnInit() {
    // Pick correct marker colors
    if (this.app.partnerVersion != "") {
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
    }
  }

  sharesValue(item: any, i: any) {
    let flip = false;
    this.data.forEach((element: any, index: any) => {
      if (element !== item) {
        if (element.bmi >= item.bmi) {
          if (element.bmi - item.bmi < 2) {
            if (i > index) flip = true;
          }
        }
        if (item.bmi >= element.bmi) {
          if (item.bmi - element.bmi < 2) {
            if (i > index) flip = true;
          }
        }
      }
    });

    return flip;
  }

  getBMILevel(val: any) {
    if (!val) {
      val = "Undefined";
    }

    let value = 30;
    if (val < 18.5) {
      value = 10;
    }
    if (val > 18.5 && val < 25) {
      value = 30;
    }
    if (val > 25 && val < 30) {
      value = 50;
    }
    if (val > 30 && val < 35) {
      value = 70;
    }
    if (val > 35) {
      value = 90;
    }

    // Account for inbetween  values
    if (val == 25) {
      value = 40;
    }
    if (val == 30) {
      value = 60;
    }
    if (val == 35) {
      value = 80;
    }

    return "calc(" + value + "% - 15px)";
  }
}
