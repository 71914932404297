import { Component, OnInit, Input } from "@angular/core";
import BacteriaDivision from "../../assets/json/bacteriaDivision.json";
import { AppComponent } from "../../app/app.component";

@Component({
  selector: "app-comparison-bacteria-levels",
  templateUrl: "./comparison-bacteria-levels.component.html",
  styleUrls: ["./comparison-bacteria-levels.component.css"],
})
export class ComparisonBacteriaLevelsComponent implements OnInit {
  @Input() data: any = {};

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: ["iprobio_blue", "iprobio_blue_light", "iprobio_green", "iprobio_red", "iprobio_blue_dark"],
  }
  legendColors: any = this.legendColorsVariants['default'];
  goodBacteria: any[] = [];
  goodBacteriaTEST: any[] = [];
  badBacteria: any[] = [];
  relevantBacteria: any[] = [];

  division: any;

  bacteriaDescription: any;

  partnerImgSrc: string = '';

  constructor(public app: AppComponent) {}

  ngOnInit() {
    // Pick correct marker colors
    if (this.app.partnerVersion != "") {
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
      this.partnerImgSrc = '-' + this.app.partnerVersion;
    }
    this.division = BacteriaDivision;
    this.divideBacterias();
  }

  toggleBacteriaDescription(bacteria) {
    bacteria
      ? (this.bacteriaDescription = bacteria)
      : (this.bacteriaDescription = "");
  }
  sharesValue(item: any, i: any, values: any) {
    let flip = false;
    values.forEach((element: any, index: any) => {
      if (element !== item) {
        let tempItemLevel = this.getBacteriaLevel(
          item.range[0],
          item.range[1],
          item.value,
        ).replace("%", "");
        let tempElementLevel = this.getBacteriaLevel(
          element.range[0],
          element.range[1],
          element.value,
        ).replace("%", "");
        let diff = Math.abs(Number(tempItemLevel) - Number(tempElementLevel));

        if (diff < 1) {
          if (i > index) flip = true;
        }
      }
    });

    return flip;
  }
  divideBacterias() {
    this.data.forEach((elem: any) => {
      elem.top35.forEach((element: any) => {
        let temp = {
          name: element.bacteria,
          values: [],
        };
        let tempValues = {
          date: elem.date,
          range: element.range,
          value: element.value,
        };
        temp.values.push(tempValues);

        if (Object.values(this.division.good).indexOf(element.bacteria) > -1) {
          if (this.goodBacteria.find((x) => x.name === element.bacteria)) {
            let existingBacteria = this.goodBacteria.find(
              (x) => x.name === element.bacteria,
            );
            existingBacteria.values.push(tempValues);
          } else {
            this.goodBacteria.push(temp);
          }
        }
        if (Object.values(this.division.bad).indexOf(element.bacteria) > -1) {
          if (this.badBacteria.find((x) => x.name === element.bacteria)) {
            let existingBacteria = this.badBacteria.find(
              (x) => x.name === element.bacteria,
            );
            existingBacteria.values.push(tempValues);
          } else {
            this.badBacteria.push(temp);
          }
        }
        if (
          Object.values(this.division.relevant).indexOf(element.bacteria) > -1
        ) {
          if (this.relevantBacteria.find((x) => x.name === element.bacteria)) {
            let existingBacteria = this.relevantBacteria.find(
              (x) => x.name === element.bacteria,
            );
            existingBacteria.values.push(tempValues);
          } else {
            this.relevantBacteria.push(temp);
          }
        }
      });
    });
  }

  getBacteriaLevel(left: number, right: number, value: string) {
    const y: number = +value; // needed to parse the string
    let cal = ((y - left) * 100) / (right - left);
    if (cal > 100 || cal < 0) {
      cal = cal * 1.3;
    }

    if (-2 < cal && cal < 0) {
      cal = cal * 20;
    } else if (-3 < cal && cal < -2) {
      cal = cal * 2;
    }

    if (95 < cal && cal < 100) {
      cal = 95;
    }
    if (cal > 116) {
      cal = 116;
    }
    if (cal < -20) {
      cal = -20;
    }
    return cal + "%";
  }
}
