import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../api/calculations/dashboard";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { AppComponent } from "../../app/app.component";

@Component({
  selector: "app-kit-comparison",
  templateUrl: "./kit-comparison.component.html",
  styleUrls: ["./kit-comparison.component.css"],
})
export class KitComparisonComponent implements OnInit {
  constructor(
    public dashboardService: DashboardService,
    public dietaryDashService: DietaryDashService,
    public app: AppComponent
  ) {}

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: ["iprobio_blue", "iprobio_blue_light", "iprobio_green", "iprobio_red", "iprobio_blue_dark"],
  }
  legendColors: any = this.legendColorsVariants['default'];

  kits: any = JSON.parse(sessionStorage.getItem("ComparisonKits"));
  kitsData: any = [];
  isDataLoaded: boolean = false;

  ngOnInit() {
    // Pick correct marker colors
    if (this.app.partnerVersion != "") {
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
    }
    this.getKitData();
  }

  async getKitData() {
    const kitPromises = this.kits.map(async (element: any) => {
      try {
        let response = await this.dashboardService.GetDashboardBacteria(
          element.id,
        );
        if (response.status === 200) {
          let bmiValue = (await this.dashboardService.GetBmi(element.id)).data;
          // let nutrientValues = await (
          //   await this.dietaryDashService.GetNutrientAdvice(element.id)
          // ).data;
          let temp = {
            id: element.id,
            date: element.date,
            name: element.name,
            bmi: bmiValue,
            //@ts-ignore
            shannon: response.data.graphs.find(
              (x: any) => x.graph === "Shannon",
            ).value,
            //@ts-ignore
            otus: response.data.graphs.find((x: any) => x.graph === "OTUs")
              .value,
            //@ts-ignore
            pielou: response.data.graphs.find(
              (x: any) => x.graph === "Evenness",
            ).value,
            data: response.data,
            //@ts-ignore
            top35: response.data.top35,
            // nutrientValues: nutrientValues,
          };
          this.kitsData.push(temp);
        }
      } catch (error) {
        console.error(error);
      }
    });
    await Promise.all(kitPromises);
    this.kitsData.sort(function (a: any, b: any) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      a = new Date(a.date);
      b = new Date(b.date);
      return a - b;
    });

    this.isDataLoaded = true;
  }
}
