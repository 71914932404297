import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IEyyo } from '../../../interfaces/advice';
import BacteriaDivision from '../../assets/json/bacteriaDivision.json';
import { DashboardService } from "../api/calculations/dashboard";
import { ActivatedRoute } from "@angular/router";
import { TestKitIdService } from "../testkit-id-get/testkitid.service"
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { AppComponent } from '../app.component';
import { SubjectService } from '../api/test/subject';
import { ITestKit, ISubject } from 'interfaces/tests';
import top35 from '../../assets/json/top35.json';


@Component({
  selector: 'app-bacteria-levels',
  templateUrl: './bacteria-levels.component.html',
  styleUrls: ['./bacteria-levels.component.css'],
})
export class BacteriaLevelsComponent implements OnInit {
  @Input() hideSectionInPdf: boolean = false;
  constructor(
    private translate: TranslateService,
    public dashboardService: DashboardService,
    private route: ActivatedRoute,
    private testKitIdService: TestKitIdService,
    public app: AppComponent,
    private subjectService: SubjectService) {}

  gutHealth;
  dashBacteria: IEyyo;
  division;
  goodBacteria: any[] = [];
  badBacteria: any[] = [];
  relevantBacteria: any[] = [];
  bacteriaDescription: any;
  testKitID: number;
  bacteriaList: string;
  bacteriaJson: any[] = [];
  testKitIDString: string;
  subjectArray: ITestKit;
  subjectID: number;
  subjectName: string;
  testDate: string;
  formattedDate: string;


  partnerImgSrc = '';

  async ngOnInit() {

    if (this.app.partnerVersion != ''){
      this.partnerImgSrc = '-' + this.app.partnerVersion;
    }

    this.dashBacteria = JSON.parse(sessionStorage.getItem('Dashboard'));

    console.log(this.dashBacteria);
    this.testKitID = this.testKitIdService.getTestKitID();
    this.testKitIDString = this.testKitID.toString();

    // this.gutHealth = this.dashBacteria.graphs.find(
    //   (x) => x.graph === "Gut Health"
    // ).value;
    this.division = BacteriaDivision;
    this.divideBacterias();

    try {
      this.getBacteriaList();
    } catch (error) {
      console.error(error);
    }

    // Printing Subject Name & date is PDF document
    const response = (await this.subjectService.GetSubjectByTestkit(this.testKitID));
    this.subjectArray = response.data;
    this.subjectID = +this.subjectArray;
    const test = (await this.subjectService.GetTestKit(this.testKitID)).data;
    const dateObj = new Date(test.test_date);
    console.log("Test date unformatted: " + test.test_date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    this.formattedDate = `${day}/${month}/${year}`;
    const response2 = (await this.subjectService.GetSubject(this.subjectID)).data;
    this.subjectName = response2.full_name;
  }


  async getBacteriaList() {
    try {
      this.bacteriaList = (await this.dashboardService.GetFullBacteriaList(this.testKitID)).data;
  
      const dataArray = this.bacteriaList.split('\n');
  
      const bacteriaData = dataArray.map((line, index) => {
        if (index === 0 || !line.trim()) {
          return null;
        }
        const columns = line.split(',');
        const bacteriaNameArray = columns[1].split(';');
        const bacteriaName = `${bacteriaNameArray[bacteriaNameArray.length - 2]} ${bacteriaNameArray[bacteriaNameArray.length - 1]}`.replace(/[_[\];]/g, ' ')
        .trim()
        .replace(/\s+/g, ' ');
        const count = parseInt(columns[2], 10);
        const percentage = parseFloat(columns[3]);

        if (count === 0 && percentage === 0 && !top35["top35"].includes(bacteriaName.split(' ')[1])) {
          return null;
        }

        return {
          bacteriaName,
          count,
          percentage,
        };
      }).filter((item) => item !== null);

  
      this.bacteriaJson = bacteriaData.sort((a, b) => {
        const nameA = a.bacteriaName.toLowerCase();
        const nameB = b.bacteriaName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          const nameA2 = a.bacteriaName.split(' ')[0].toLowerCase();
          const nameB2 = b.bacteriaName.split(' ')[0].toLowerCase();
          if (nameA2 < nameB2) {
            return -1;
          } else if (nameA2 > nameB2) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  divideBacterias() {
    this.dashBacteria.top35.forEach((elem) => {
      if (Object.values(this.division.good).indexOf(elem.bacteria) > -1) {
        this.goodBacteria.push(elem);
      }
      if (Object.values(this.division.bad).indexOf(elem.bacteria) > -1) {
        this.badBacteria.push(elem);
      }
      if (Object.values(this.division.relevant).indexOf(elem.bacteria) > -1) {
        if(elem.bacteria==="Clostridium_sensu_stricto_1"){
          elem.bacteria="Clostridium"
        }
        this.relevantBacteria.push(elem);
      }
      console.log(this.relevantBacteria);
    });
  }

  toggleBacteriaDescription(bacteria) {
    bacteria
      ? (this.bacteriaDescription = bacteria)
      : (this.bacteriaDescription = '');
  }


  getBacteriaLevel(bacteria) {
    
    // @ts-ignore: Object is possibly 'null'.
    const dif = ((bacteria.range[1] - bacteria.range[0]) / 70) * 15;
    // @ts-ignore: Object is possibly 'null'.
    const refUp = bacteria.range[1] + dif; // potential for gas production
    // @ts-ignore: Object is possibly 'null'.
    const refLow = bacteria.range[0] - dif; // potential for gas production
    // @ts-ignore: Object is possibly 'null'.
    let calOld = ((bacteria.value - refLow) / (refUp - refLow)) * 100;
    let cal = ((bacteria.value - bacteria.range[0]) * 70) / (bacteria.range[1] - bacteria.range[0]) + 15
    if (cal < 2) {
      cal = 2;
    }
    if (cal > 98) {
      cal = 98;
    }

    if(bacteria.bacteria == 'Sutterella') {
      console.log(cal)
      console.log(calOld)
      console.log(bacteria.value)
      console.log(bacteria.range[0])
      console.log(bacteria.range[1])
    }

    return "calc(" + cal + "% - 15px)";
  }

  async downloadBacList() {

    let partnerAddon = ''
    let r = 57;
    let g = 17;
    let b = 97;
    if (this.app.partnerVersion != '') {
      partnerAddon = '-' + this.app.partnerVersion
      r = 8;
      g = 29;
      b = 58;
    }

    let PdfImg = './assets/Bacteria_List_First_'+ this.app.selectedLanguage.toUpperCase() +'-1'+ partnerAddon +'.png'
    this.translate.get('YOUR_BACTERIA_LEVELS.GOOD_BACTERIA')['value']
    const doc = new jsPDF();

    doc.addImage(PdfImg, 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setFontSize(20);
    doc.addFont('./assets/fonts/Ubuntu-Regular.ttf', 'UbuntuFont', 'normal');
    doc.setFont('UbuntuFont');
    doc.text(this.returnTranslation('WEBSITE_REDESIGN_DIMITAR.NAME_TEXT') + this.subjectName, 20, 80);
    doc.text(this.returnTranslation('WEBSITE_REDESIGN_DIMITAR.ACTIVATION_DATE_TEXT') + this.formattedDate, 20, 90);
    doc.text(this.returnTranslation('WEBSITE_REDESIGN_DIMITAR.ID_TEXT')  + this.testKitIDString, 20, 100);
    doc.addPage();
    // Bacteria List to table
    const columns = {
      bacteria: this.returnTranslation('WEBSITE_REDESIGN_DIMITAR.BACTERIUM'), 
      count: this.returnTranslation('WEBSITE_REDESIGN_DIMITAR.COUNT') , 
      percentage: this.returnTranslation('WEBSITE_REDESIGN_DIMITAR.PERCENTAGE') 
    };
    const data = [];
    for (let i = 0; i < this.bacteriaJson.length; i++) {
      const row = [];
      row.push(this.bacteriaJson[i].bacteriaName);
      row.push(this.bacteriaJson[i].count.toString());
      row.push(this.bacteriaJson[i].percentage);
      data.push(row);
    }
    autoTable(doc, {
      startY: 20,
      head: [columns],
      headStyles: {
        fillColor: [r, g, b], 
      },
      body: data,
      columnStyles: {
        bacteria: {
          font: 'UbuntuFont'
        },
        count: {
          font: 'UbuntuFont'
        },
        percentage: {
          font: 'UbuntuFont'
        }
      },
      styles: {
        font: 'UbuntuFont',
        fontSize: 12,
        cellPadding: 5,
        overflow: 'linebreak',
      },
      margin: { top: 10 },
    });

    doc.save(this.returnTranslation('WEBSITE_REDESIGN_DIMITAR.PDF_NAME')  + this.testKitID + '.pdf');
  }

  // Split every bacteria in different column. 
  downloadBacCsv() { 
    const locale = window.navigator.language;
    // Helper function for setting percentage denominator - either dot or comma
    function formatPercentage(value, locale) {
      const numberFormat = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 9,
        maximumFractionDigits: 9,
      });
      return numberFormat.format(value);
    }
  
  
    // Use tab character as delimiter
    const delimiter = '\t'; 
    const csvData = this.bacteriaList.split('\n').slice(1).map((row) => {
      if (row) {
        const [id, bacteria, countStr, percentageStr] = row.split(',');
        const count = parseInt(countStr);
        const percentage = parseFloat(percentageStr);
        const formattedPercentage = formatPercentage(percentage, locale);
        const [domain, phylum, classs, order, family, genus] = bacteria.split(';');
        if (count === 0 && percentage === 0 && !top35["top35"].includes(genus) && !top35["top35"].includes(family)) {
          return null;
        }
        return [domain, phylum, classs, order, family, genus, count, formattedPercentage];
      } else {
        return null;
      }
    }).filter(row => row !== null);
  
    let rowTranslations = {
      en: ['Domain', 'Phylum', 'Class', 'Order', 'Family', 'Genus', 'Count', 'Percentage'],
      nl: ['Rijk', 'Stam', 'Klasse', 'Orde', 'Familie', 'Genus', 'Telling', 'Percentage'],
      it: ['Dominio', 'Phylum', 'Classe', 'Ordine', 'Famiglia', 'Genere', 'Conteggio', 'Percentuale'],
      sk: ['Doména', 'Kmeň', 'Trieda', 'Rad', 'Čeľaď', 'Rod', 'Počet', 'Percento']
    }

    // Check user-selected language and base headers on that
    const headerRow = rowTranslations[this.app.selectedLanguage]

    const fileName = this.app.selectedLanguage === "en" ? "Bacteria_list_"
    : this.app.selectedLanguage === "it" ? "Elenco_batteri_"
    : this.app.selectedLanguage === "sk" ? "Zoznam_baktérií_"
    : "Bacterielijst_";
  
    const csvContent = "data:text/csv;charset=utf-8," + headerRow.join(delimiter) + '\n' + csvData.map(row => row.join(delimiter)).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + this.testKitID + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  returnTranslation(tl_value){
    let output;
    this.translate.get(tl_value).subscribe((text: string) => {
      output = text;
    });
    return output;
  }
}


