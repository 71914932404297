import {Component, OnInit} from '@angular/core';
import {IOrder, IOrderItem} from '../../../interfaces/orders';
import {TranslateService} from "@ngx-translate/core";
import {UtilityService} from "../utility.service";
import {AuthenticationService} from "../api/login/authentication";
import {Router} from "@angular/router";

declare var fbq: Function;
declare let dataLayer: any[];

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  constructor(private translate: TranslateService, private utils: UtilityService, public auth: AuthenticationService, public router: Router) {
  }

  title = 'Your shopping cart';
  btnBackShopping = 'Back to shopping';
  btnFinalize = 'Finalize purchase';

  basket: IOrder;

  ngOnInit() {
    try {
      if (localStorage.getItem('Basket')) {
        this.basket = JSON.parse(localStorage.getItem('Basket'));
      }
    } catch {
      this.utils.showWarning('NEW_USER_WARNING', 'NEW_USER_WARNING');
    }

    dataLayer.push({  ecommerce: null});

      dataLayer.push({
        
        event: "view_cart",
        ecommerce: {
          currency: "EUR",
          value: this.basket.total_price,
          items: [
            {
              item_id: '1',
              item_name: "Microbiome test kit",
              price: 185,
              quantity: this.basket.total_price / 185
            }
          ]
        }
      });
  }

  updateCartNumber() {
    this.basket = JSON.parse(localStorage.getItem('Basket'));
    let total = 0;
    for (const item of this.basket.order_items) {
      total += item.quantity;
    }
    (document.getElementById('shoppingCartNumber') as HTMLSpanElement).textContent = total.toString();
  }

  removeRow(row: IOrderItem) {
    const index: number = this.basket.order_items.indexOf(row);
    if (index !== -1) {
      this.basket.order_items.splice(index, 1);
      this.basket.total_price -= row.total_price;
      localStorage.setItem('Basket', JSON.stringify(this.basket));
      this.updateCartNumber();

      dataLayer.push({  ecommerce: null});

      dataLayer.push({
        
        event: "add_to_cart",
        ecommerce: {
          currency: "EUR",
          value: 185,
          items: [
            {
              item_id: '1',
              item_name: "Microbiome test kit",
              price: 185,
              quantity: 1
            }
          ]
        }
      });
    }
  }

  finalizePurchase() {
    fbq('track', 'InitiateCheckout', {currency: "EUR", value: this.basket.total_price});
    this.router.navigate(['payment-details']);
  }
}
