import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import {
  IEyyo,
  INutrientAdvice,
  IPrebioticCompound,
  IPrebioticSupplement,
  IProbioticPrebioticAdvice,
  IProbioticResult,
} from "../../../interfaces/advice";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { AppComponent } from "../app.component";
import { DashboardService } from "../api/calculations/dashboard";
import { TestKitIdService } from "../testkit-id-get/testkitid.service";
import top35 from "../../assets/json/top35.json";
import BacteriaDivision from "../../assets/json/bacteriaDivision.json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

@Component({
  selector: "app-supplement-advice",
  templateUrl: "./supplement-advice.component.html",
  styleUrls: ["./supplement-advice.component.css"],
})
export class SupplementAdviceComponent implements OnInit {
  testKitID: number;
  // Bacteria graphs
  bacteriaJson: any[] = [];
  selectedBacteria: any[] = [];
  specificBacteriaNames = [
    "Lactobacillus",
    "Faecalibacterium",
    "Akkermansia",
    "Bifidobacterium",
  ];
  dashBacteria: IEyyo;
  division;
  bacteriaDescription: any;

  // Pre/Probiotics
  probioticResults: IProbioticResult[] = [];
  groupedProbioticResults: { [key: string]: IProbioticResult[] } = {};
  // Prebiotic supplements data
  prebioticSupplements: IPrebioticSupplement[] = [];
  prebioticCompounds: IPrebioticCompound[] = [];

  medicalTagImages = {
    1: "assets/medical_tags/ibs.png",
    2: "assets/medical_tags/gluten.png",
    3: "assets/medical_tags/lactose.png",
    4: "assets/medical_tags/histamine.png",
  };
  showIbsTag = false;
  showGlutenTag = false;
  showLactoseTag = false;
  showHistamineTag = false;

  // Dropdown states for probiotics and prebiotics
  dropdownState = {
    probioticsAnalysis: false,
    prebioticsAnalysis: false,
    probioticsRec: false,
    prebioticsRec: false,
    probioticStrainsCategories: false,
    dynamicProbioticsDropdownState: {} as { [key: string]: boolean },
    prebioticFoods: false,
    prebioticSupplements: false,
  };

  // Sub-dropdown states
  subDropdownProbioticsRecGuidelinesState = {
    dropdown_guidelines_3_1: false,
    dropdown_guidelines_3_2: false,
    dropdown_guidelines_3_3: false,
    dropdown_guidelines_3_4: false,
  };
  subDropdownProbioticsRecFAQState = {
    dropdown_faq_4_1: false,
    dropdown_probiotics_rec_faq_4_2: false,
    dropdown_faq_4_3: false,
    dropdown_faq_4_4: false,
    dropdown_faq_4_5: false,
    dropdown_faq_4_6: false,
    dropdown_faq_4_7: false,
  };
  subDropdownPrebioticsRecGuidelinesState = {
    dropdown_guidelines_3_1: false,
    dropdown_guidelines_3_2: false,
    dropdown_guidelines_3_3: false,
  };
  subDropdownPrebioticsRecFAQState = {
    dropdown_faq_4_1: false,
    dropdown_probiotics_rec_faq_4_2: false,
    dropdown_faq_4_3: false,
    dropdown_faq_4_4: false,
    dropdown_faq_4_5: false,
    dropdown_faq_4_6: false,
    dropdown_faq_4_7: false,
    dropdown_faq_4_8: false,
    dropdown_faq_4_9: false,
    dropdown_faq_4_10: false,
  };

  // Nutrient chart-related variables
  carbohydratesValue = 5;
  carbohydratesValueRanges: any = [];
  fatValue = 4;
  fatValueRanges: any = [];
  saturatedFatValue = 3;
  saturatedFatValueRanges: any = [];

  fiberChartValues: any = [];
  fiberValue: any;
  saltChartValues: any = [];

  proteineObject: INutrientAdvice;
  saltObject: INutrientAdvice;
  fibreObject: INutrientAdvice;
  carbohydratesObject: INutrientAdvice;
  fatObject: INutrientAdvice;
  satFatObject: INutrientAdvice;

  nutrientValues: INutrientAdvice[] = [];

  lowerFiber = 10;
  mediumFiber = 10;
  higherFiber = 10;
  lowerSalt = 0;
  mediumSalt = 6;
  higherSalt = 2;

  partnerImgSrc = "";
  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private testKitIdService: TestKitIdService,
    private translate: TranslateService,
    public dietaryDashService: DietaryDashService,
    public app: AppComponent
  ) {}

  //////////////////////////////////////////////////////////////////////////////
  async ngOnInit() {
    this.testKitID = Number(this.route.snapshot.paramMap.get("id"));
    window.scrollTo(0, 0);

    // Load nutrient values
    this.loadNutrientValues();
    // Bacteria graphs
    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));
    this.division = BacteriaDivision;
    try {
      await this.getBacteriaList(); // Fetch and process bacteria list
      this.filterSpecificBacteria(); // Filter data for the four bacteria to display
    } catch (error) {
      console.error("Error fetching bacteria data:", error);
    }

    this.getProbioticAndPrebioticData();
  }
  //////////////////////////////////////////////////////////////////////////////
  // Bacteria graphs
  async getBacteriaList() {
    try {
      const bacteriaList = (
        await this.dashboardService.GetFullBacteriaList(this.testKitID)
      ).data;
      const dataArray = bacteriaList.split("\n");

      // Process each row and store bacteria data in an array
      this.bacteriaJson = dataArray
        .map((line, index) => {
          if (index === 0 || !line.trim()) return null;
          const columns = line.split(",");
          const bacteriaNameArray = columns[1].split(";");
          const bacteriaName = `${
            bacteriaNameArray[bacteriaNameArray.length - 2]
          } ${bacteriaNameArray[bacteriaNameArray.length - 1]}`
            .replace(/[_[\];]/g, " ")
            .trim();
          const count = parseInt(columns[2], 10);
          const percentage = parseFloat(columns[3]);

          // Exclude bacteria with 0 counts/percentages if they are not in top35
          if (
            count === 0 &&
            percentage === 0 &&
            !top35["top35"].includes(bacteriaName.split(" ")[1])
          ) {
            return null;
          }

          return { bacteriaName, count, percentage };
        })
        .filter((item) => item !== null);
    } catch (error) {
      console.error(error);
    }
  }

  filterSpecificBacteria() {
    this.dashBacteria.top35.forEach((elem) => {
      if (Object.values(this.division.good).indexOf(elem.bacteria) > -1) {
        if (this.specificBacteriaNames.includes(elem.bacteria)) {
          this.selectedBacteria.push(elem);
        }
      }
    });
    // Output the filtered list for verification
    console.log("Filtered Bacteria List:", this.selectedBacteria);
  }

  toggleBacteriaDescription(bacteria) {
    bacteria
      ? (this.bacteriaDescription = bacteria)
      : (this.bacteriaDescription = "");
  }

  getBacteriaLevel(left: number, right: number, value: string) {
    const y: number = +value; // needed to parse the string
    let cal = ((y - left) * 100) / (right - left);
    if (cal > 100 || cal < 0) {
      cal = cal * 1.3;
    }

    if (-2 < cal && cal < 0) {
      cal = cal * 20;
    } else if (-3 < cal && cal < -2) {
      cal = cal * 2;
    }

    if (95 < cal && cal < 100) {
      cal = 95;
    }
    if (cal > 116) {
      cal = 116;
    }
    if (cal < -20) {
      cal = -20;
    }
    return cal + "%";
  }

  //////////////////////////////////////////////////////////////////////////////
  // Nutrient chart logic
  async loadNutrientValues() {
    if (sessionStorage.getItem("TargetNutValues-" + this.testKitID)) {
      this.nutrientValues = JSON.parse(
        sessionStorage.getItem("TargetNutValues-" + this.testKitID)
      );
    } else {
      this.nutrientValues = await (
        await this.dietaryDashService.GetNutrientAdvice(this.testKitID)
      ).data;
    }

    this.fibreObject = this.nutrientValues.find((x) => x.type === "fibers");
    this.calculateDietaryFiberChart(this.fibreObject);

    console.log(this.fiberValue);
  }

  calculateDietaryFiberChart(fiberObject: INutrientAdvice) {
    // Copy logic directly from TargetValuesComponent
    if (fiberObject.value < 20) {
      this.lowerFiber = 30;
    }
    if (fiberObject.value > 40) {
      this.higherFiber =
        (fiberObject.value % 10 === 0
          ? fiberObject.value
          : fiberObject.value - (fiberObject.value % 5) + 5) -
        this.mediumFiber -
        this.higherFiber;
    }

    const total = this.lowerFiber + this.mediumFiber + this.higherFiber;

    this.fiberChartValues = [
      fiberObject.range[0] + "%",
      fiberObject.range[1] - fiberObject.range[0] + "%",
      100 - fiberObject.range[1] + "%",
    ];

    this.fiberValue = this.getDietaryFiberLevel();
  }
  getDietaryFiberLevel() {
    const fiberValue = this.nutrientValues.find((x) => x.type === "fibers");
    const ref = this.lowerFiber + this.mediumFiber + this.higherFiber;
    if (fiberValue.value > 20) {
      fiberValue.value = fiberValue.value - 20;
    }
    const cal = (fiberValue.value / ref) * 100;
    // return "calc(" + cal + "% - 15px)";
    return "calc(" + fiberValue.value + "% - 15px";
  }

  getFiberLevelAnalysisText(): string {
    // Check if fiberValue is in the format of "calc(XX% - YYpx)"
    const match = this.fiberValue.match(/calc\((\d+)%/); // Extract the percentage value from the calc() string
    const fiberValueNum = match ? parseFloat(match[1]) : NaN; // If match exists, convert to number

    console.log("Extracted Fiber Value:", fiberValueNum);

    // If fiberValueNum is NaN, handle it gracefully
    if (isNaN(fiberValueNum)) {
      console.error("fiberValue is not a valid number.");
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_1_BE_AWARE"
      );
    }

    const lowerBound = parseFloat(this.fiberChartValues[0]); // Lower range of the fiber graph
    const middleRange = parseFloat(this.fiberChartValues[1]); // Middle range of the fiber graph
    const upperBound = lowerBound + middleRange;

    // Determine which text to display based on the fiber value
    if (fiberValueNum <= lowerBound) {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_1_BE_AWARE"
      );
    } else if (fiberValueNum <= upperBound) {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_1_NORMAL"
      );
    } else {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_1_GREAT"
      );
    }
  }

  //////////////////////////////////////////////////////////////////////////////

  // Dropdown toggling logic with grouping
  toggleDropdown(dropdownKey: string) {
    this.dropdownState[dropdownKey] = !this.dropdownState[dropdownKey];
  }

  getProbioticAndPrebioticData() {
    this.dietaryDashService
      .GetSupplementAdvice(this.testKitID, this.translate.getDefaultLang())
      .then((data: IProbioticPrebioticAdvice | undefined) => {
        if (data) {
          // Probiotic data
          this.probioticResults = data.probiotic_results;

          // Group the results by category
          this.groupedProbioticResults = this.probioticResults.reduce(
            (acc, current) => {
              const category = current.category;
              if (!acc[category]) {
                acc[category] = [];
              }
              acc[category].push(current);
              return acc;
            },
            {}
          );

          console.log(this.groupedProbioticResults);

          // Prebiotic supplements data
          this.prebioticSupplements = data.prebiotic_supplements;

          // Prebiotic compounds data from backend
          this.prebioticCompounds = data.prebiotic_compounds;
          console.log(this.prebioticCompounds);
          this.orderPrebioticCompounds();

          this.getLegendMedicalTags();
        }
      })
      .catch((error) => {
        console.error("Error fetching probiotic recommendations: ", error);
      });
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  groupedBySubcategory(probiotics: IProbioticResult[]): {
    [key: string]: string[];
  } {
    const grouped: { [key: string]: string[] } = {};

    // Group species by their function (subcategory)
    probiotics.forEach((probiotic) => {
      const subcategory = probiotic.function;
      if (!grouped[subcategory]) {
        grouped[subcategory] = [];
      }
      grouped[subcategory].push(probiotic.probiotic_strain);
    });

    return grouped;
  }

  getProbioticSpecies(strain: string): string {
    const probiotic = this.probioticResults.find(
      (item) => item.probiotic_strain === strain
    );
    return probiotic ? probiotic.species : "";
  }

  getProbioticNomenclature(strain: string): string {
    const probiotic = this.probioticResults.find(
      (item) => item.probiotic_strain === strain
    );
    return probiotic ? probiotic.different_nomenclature : "";
  }

  selectedStrain: string = "";
  selectedPrebioticBacteria: string = "";
  selectedPrebioticCompound: string = "";

  toggleStrainInfo(strain: string) {
    this.selectedStrain = this.selectedStrain === strain ? "" : strain;
  }
  togglePrebioticCompoundInfo(compound: string) {
    this.selectedPrebioticCompound =
      this.selectedPrebioticCompound === compound ? "" : compound;
  }

  togglePrebioticBacteriaInfo(bacteria: string) {
    this.selectedPrebioticBacteria =
      this.selectedPrebioticBacteria === bacteria ? "" : bacteria;
  }

  orderPrebioticCompounds() {
    // Find the relevant supplement for Faecalibacterium
    const faecalibacteriumSupplement = this.prebioticSupplements.find(
      (supplement) => supplement.bacteria.trim() === "Faecalibacterium"
    );

    // If found, map the compounds, otherwise return an empty array
    const supplementCompoundsOrder = faecalibacteriumSupplement
      ? faecalibacteriumSupplement.prebiotic_compounds.map((compound) =>
          compound.trim()
        )
      : [];

    // Sort the prebioticCompounds based on the order in the supplements for Faecalibacterium
    this.prebioticCompounds.sort((a, b) => {
      const indexA = supplementCompoundsOrder.indexOf(
        a.prebiotic_compound.trim()
      );
      const indexB = supplementCompoundsOrder.indexOf(
        b.prebiotic_compound.trim()
      );
      // If a compound is not found in the supplement order, it stays at the end of the list.
      return (
        (indexA === -1 ? supplementCompoundsOrder.length : indexA) -
        (indexB === -1 ? supplementCompoundsOrder.length : indexB)
      );
    });
  }

  toggleProbioticCategoryDropdown(category: string) {
    this.dropdownState.dynamicProbioticsDropdownState[category] =
      !this.dropdownState.dynamicProbioticsDropdownState[category];
  }

  toggleSubDropdownProbioticsRecGuidelines(key: string): void {
    this.subDropdownProbioticsRecGuidelinesState[key] =
      !this.subDropdownProbioticsRecGuidelinesState[key];
  }

  // Method to toggle the FAQ dropdowns for probiotics recommendations
  toggleSubDropdownProbioticsRecFAQ(key: string): void {
    this.subDropdownProbioticsRecFAQState[key] =
      !this.subDropdownProbioticsRecFAQState[key];
  }

  // Similarly, for prebiotics dropdowns, add their toggling methods
  toggleSubDropdownPrebioticsRecGuidelines(key: string): void {
    this.subDropdownPrebioticsRecGuidelinesState[key] =
      !this.subDropdownPrebioticsRecGuidelinesState[key];
  }

  toggleSubDropdownPrebioticsRecFAQ(key: string): void {
    this.subDropdownPrebioticsRecFAQState[key] =
      !this.subDropdownPrebioticsRecFAQState[key];
  }

  // Download PDF
  async downloadPDF() {
    try {
      const pdfData = await this.dietaryDashService.GetSupplementAdvicePDF(
        this.testKitID,
        this.app.selectedLanguage
      );

      if (pdfData && pdfData.pdf_link && pdfData.pdf_link.file_url) {
        const pdfUrl = pdfData.pdf_link.file_url;

        // Create a hidden link element
        const link = document.createElement("a");
        link.href = pdfUrl;

        // Set the target to open in a new tab
        link.setAttribute("target", "_blank");

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to open the PDF in a new tab
        link.click();

        // Remove the link from the document after the PDF is opened
        document.body.removeChild(link);
      } else {
        console.error("PDF download link not found");
      }
    } catch (error) {
      console.error("Error downloading PDF: ", error);
    }
  }

  getLegendMedicalTags() {
    // Loop through each prebiotic compound
    this.prebioticCompounds.forEach((compound) => {
      const tags = Object.keys(compound.medical_tags);
      tags.forEach((tagId) => {
        const id = parseInt(tagId, 10);

        switch (id) {
          case 1: // IBS
            this.showIbsTag = true;
            break;
          case 2: // Gluten
            this.showGlutenTag = true;
            break;
          case 3: // Lactose
            this.showLactoseTag = true;
            break;
          case 4: // Histamine
            this.showHistamineTag = true;
            break;
          default:
            break;
        }

        // Check if all flags are true, if so, break the loop
        if (
          this.showIbsTag &&
          this.showGlutenTag &&
          this.showLactoseTag &&
          this.showHistamineTag
        ) {
          return; // Breaks the inner loop
        }
      });
    });
  }

  getMedicalTagImage(tagId: number): string | null {
    return this.medicalTagImages[tagId] || null;
  }
}
