import {Component, OnInit} from '@angular/core';
import * as Survey from 'survey-angular';
import {Model, SurveyNG, NumericValidator } from 'survey-angular';
import {SurveyService} from '../api/survey/survey';
import {ActivatedRoute, Router} from '@angular/router';
import {SubjectService} from '../api/test/subject';
import {IUserSurveyAnswer} from '../../../interfaces/surveys';
import {UtilityService} from '../utility.service';
import { Converter } from "showdown";
import { TranslateService } from "@ngx-translate/core";
import * as surveyData from "./survey-data.json"
// import 'survey-angular/modern.css';
Survey.StylesManager.applyTheme('bootstrap');

@Component({
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  constructor(private surveyService: SurveyService, private testkitService: SubjectService, private translate: TranslateService,
              private route: ActivatedRoute, private router: Router, private utils: UtilityService) {
  }

  userAnswers: IUserSurveyAnswer[] = [];
  surveyJSON;
  testResults;
  testSubject;
  testResultID;
  survey;
  questions;

  async ngOnInit() {
    this.testResultID = Number(this.route.snapshot.paramMap.get('id'));
    this.testResults = (await this.testkitService.GetTestKit(this.testResultID)).data;

    console.log(this.testResultID);

    const myCss = {
      navigation: {
        complete: 'sv_complete_btn',
        prev: 'sv_prev_btn',
        next: 'sv_next_btn',
      },
      root: 'sv_main sv_bootstrap_css',
      container: 'sv_container',
      progressTop: 'progress center-block mx-auto mb-4',
      progressBar: 'progress-bar',
      progressText: 'sv-hidden',
      row: 'sv_row',
      question: {
        mainRoot: 'sv_qstn',
      },
      rating: {
        item: 'sv-rating-default',
        selected: 'sv-rating-selected'
      }
    };

    this.testSubject = (await this.testkitService.GetSubject(this.testResults.subject_id)).data;

    if (this.testResults.uid_testkit.includes('SH-A-')) {
      this.surveyJSON = (await this.surveyService.GetSurveyById(11)).data;
      this.questions = surveyData['11']
    } else if (this.testResults.uid_testkit.includes('SK-A-')) {
      this.surveyJSON = (await this.surveyService.GetSurveyById(12)).data;
      this.questions = surveyData['12']
    } else {
      this.surveyJSON = (await this.surveyService.GetSurveyById(10)).data;
      this.questions = surveyData['10']
    }

    // this.surveyJSON = this.surveyJSONOld;
    console.log(this.surveyJSON);
    this.survey = new Model(this.surveyJSON);

    let converter = new Converter();
    this.survey.onTextMarkdown.add(function(survey, options){
      //convert the markdown text to html
      var str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.replace('<p>', '').replace('</p>', '')
      //set html
      options.html = str;
  });
    this.survey.css = myCss;
    this.survey.locale = localStorage.getItem('language');
    this.survey.onCurrentPageChanged.add(this.doOnCurrentPageChanged);
    SurveyNG.render('surveyElement', {model: this.survey});

    this.translateUi();
  }

  doOnCurrentPageChanged(survey) {
    document.getElementById('surveyComplete').style.display = survey.isLastPage ? 'inline' : 'none';
    const elements = document.getElementsByClassName('sv_complete_btn');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  async finishSurvey() {
    await this.surveyService.ClearSurvey(this.testResultID);
    this.survey.doComplete();
    const data = this.survey.data;
    for (const key in data) {
      console.log(key)
      console.log(data[key])
      // In case user selects "Other" option
      if (data[key] === 'other'){
        console.log('skiped question')
        continue
      }
      if (Array.isArray(data[key])) {
        console.log(data[key])
        for (let i = 0; i < Object.keys(data[key]).length; i++) {
          let question = this.questions.filter(obj => {return obj.name === key;})
          console.log('array question ' + question[0].id + ' ' + question[0].name)
          if (data[key][i] === 'other') {
            console.log('skiped array question')
            continue
          }
          const userAnswer: IUserSurveyAnswer = {
            testResultID: this.testResults.id,
            questionID: question[0].id,
            answerID: data[key][i],
            openAnswer: null
          };
          this.userAnswers.push(userAnswer);
        }
      } else {
        let question = this.questions.filter(obj => {return obj.name === key;})
        if (question[0].open){
          const userAnswer: IUserSurveyAnswer = {
            testResultID: this.testResults.id,
            questionID: question[0].id,
            answerID: null,
            openAnswer: data[key]
          };
          this.userAnswers.push(userAnswer);
        } else {
          const userAnswer: IUserSurveyAnswer = {
            testResultID: this.testResults.id,
            questionID: question[0].id,
            answerID: data[key],
            openAnswer: null
          };
          this.userAnswers.push(userAnswer);
        }
      }
    }
    console.log(this.survey.data);
    console.log(this.userAnswers);
    const response = await this.surveyService.SubmitSurvey(this.userAnswers);
    this.utils.showSuccess('SURVEY_SUCCESS', 'SURVEY_SUCCESS');
    await this.router.navigate(['/account-category/1']);
  }

  translateUi(){
    if (this.translate.currentLang == 'sk') {
      
      const targetNode = document.getElementById("surveyElement");
      const config = { attributes: true, childList: true, subtree: true };
      var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          if (mutation.target.textContent == 'Other (describe)') {
            mutation.target.textContent = 'Iné (popíšte)';
          }
        });
      });
      observer.observe(targetNode, config);

      let nextBtn = document.getElementsByClassName("sv_next_btn")[0] as HTMLInputElement;
      let prevBtn = document.getElementsByClassName("sv_prev_btn")[0] as HTMLInputElement;

      nextBtn.value = 'Ďalej';
      prevBtn.value = 'Späť';
    }
  }
}

