import {Component, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  tosHtml = '';

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.updateTos();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateTos();
    });
  }

  updateTos() {
    this.translate.get('TOS.PAGE_LAYOUT').subscribe((text: string) => {
      this.tosHtml = text;
    });
  }

}
