import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { concat } from "rxjs";
import { ITestKitUpdate } from "../../../interfaces";
import { ISubject } from "../../../interfaces/tests";
import { SubjectService } from "../api/test/subject";
import { UtilityService } from "../utility.service";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-human-results",
  templateUrl: "./human-results.component.html",
  styleUrls: ["./human-results.component.css"],
})
export class HumanResultsComponent implements OnInit {
  constructor(
    private subjectService: SubjectService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilityService,
    private translate: TranslateService,
    public app: AppComponent,
  ) {}

  testSubjects: ISubject[];
  categoryId: number;
  userToken: string;
  private sub: any;
  addKitForms: FormGroup;
  kitsToCompare: any = [];

  async ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.categoryId = params.id;
    });

    this.userToken = this.readToken();
    if (this.userToken != ""){
      localStorage.setItem('token', this.userToken);
    } else if (localStorage.getItem('token') == null){
      this.router.navigate(['vysledky']);
    }

    this.addKitForms = new FormGroup({
      kit_code: new FormControl(null, [Validators.required]),
    });

    const response = await this.subjectService.GetSubjects(this.categoryId);
    this.testSubjects = response.data;

    const hcp_response = await this.subjectService.GetHCPSubjects();
    this.testSubjects = this.testSubjects.concat(hcp_response.data);
  }

  async onSubmit(subjectID: number) {
    const testkit_uid = this.addKitForms.get("kit_code").value.toUpperCase();
    const updateKitCode: ITestKitUpdate = {
      uid_testkit: testkit_uid,
      subject_id: subjectID,
    };
    try {
      const response = await this.subjectService.LinkTestKit(
        updateKitCode,
        subjectID,
      );
      this.utils.showSuccess(
        "TEST_KIT_LINKED_SUCCESS",
        "TEST_KIT_LINKED_SUCCESS",
      );
    } catch {
      this.utils.showWarning(
        "TEST_KIT_NOT_FOUND_WARNING",
        "TEST_KIT_NOT_FOUND_WARNING",
      );
    }
    
    try {
      const rs = await this.subjectService.TryConenctingHCPKit(testkit_uid);
    } catch (err) {
      console.log('Error with HCP kit connection: ' + err)
    }

    try {  
      const response2 = await this.subjectService.GetSubjects(this.categoryId);
      this.testSubjects = response2.data;
      const hcp_response = await this.subjectService.GetHCPSubjects();
      this.testSubjects = this.testSubjects.concat(hcp_response.data);
      this.addKitForms.reset();
    } catch (err) {
      console.log('Error with retrieving kits: ' + err)
    }
    
  }

  convertDate(test_date: string) {
    const dateParts = test_date.split("-");
    return dateParts[2].concat("/", dateParts[1], "/", dateParts[0]);
  }

  readToken() {
    let name = "token=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  async toDashboard(subjectId, kit_uid, kit_id) {
    localStorage.setItem("k_uid" + kit_id, kit_uid);
    localStorage.setItem("s_id" + kit_id, subjectId);

    await this.router.navigate([this.app.activeLinks.dashboard + kit_id]);
  }
  isKitSelected(id: any) {
    let exists = this.kitsToCompare.indexOf((item: any) => item.id == id);

    if (exists != -1) {
      return true;
    }

    return false;
  }
  addKitToCompare(subject: any, kit: any) {
    console.log(subject);
    console.log(kit);
    if (this.kitsToCompare.length > 0) {
      let kitAdded = this.kitsToCompare.find(
        (item: any) => item.uid === kit.uid,
      );

      if (kitAdded) {
        let kitIndex = this.kitsToCompare.indexOf(kitAdded);
        this.kitsToCompare.splice(kitIndex, 1);

        return;
      }
    }
    let temp = {
      id: kit.id,
      name: subject.full_name,
      date: kit.test_date,
      uid: kit.uid_testkit,
    };
    this.kitsToCompare.push(temp);
  }

  async toKitComparison(subject: any) {
    if (this.kitsToCompare.length < 2 || this.kitsToCompare.length > 5) {
      this.utils.showError(
        "COMPARISSON_WRONG_NR_OF_KITS",
        "COMPARISSON_WRONG_NR_OF_KITS",
      );
      return;
    }
    if (this.kitsToCompare.length > 0) {
      sessionStorage.setItem(
        "ComparisonKits",
        JSON.stringify(this.kitsToCompare),
      );

      this.router.navigate(["kit-comparison"]);
      return;
    }
    let kitids = [];
    subject.test_kits.forEach((element: any) => {
      let temp = {
        id: element.id,
        date: element.test_date,
        uid: element.uid_testkit,
      };
      kitids.push(temp);
    });
    sessionStorage.setItem("ComparisonKits", JSON.stringify(kitids));

    this.router.navigate(["kit-comparison"]);
  }
}
