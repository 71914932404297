
import {Component, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-account-category',
  templateUrl: './account-category.component.html',
  styleUrls: ['./account-category.component.css']
})
export class AccountCategoryComponent implements OnInit {

  baseCategories = [
    {
      id: 1,
      name: 'ACCOUNT_CATEGORY.HUMAN_LBL',
      image: '../../assets/human_variant.png',
      addition: ''
    },
    // {
    //   id: 2,
    //   name: 'ACCOUNT_CATEGORY.PET_AND_FARM_LBL',
    //   image: '../../assets/dog.png',
    //   addition: 'No existing pets/farm animals.'
    // },
    // {
    //   id: 3,
    //   name: 'ACCOUNT_CATEGORY.SOIL_LBL',
    //   image: '../../assets/plant.png',
    //   addition: 'No existing soils.'
    // }
  ];

  categories = [
    {
      id: 1,
      name: 'Human',
      image: '../../assets/human_variant.png',
      addition: ''
    },
    // {
    //   id: 2,
    //   name: 'Pets & Farm animals',
    //   image: '../../assets/dog.png',
    //   addition: 'No existing pets/farm animals.'
    // },
    // {
    //   id: 3,
    //   name: 'Soil',
    //   image: '../../assets/plant.png',
    //   addition: 'No existing soils.'
    // }
  ];

  userId: number;

  constructor(private translate: TranslateService) {
    this.updateCategories();
    this.translate.onLangChange.subscribe(() => {
      this.updateCategories();
    });
  }

  ngOnInit() {
  }

  updateCategories() {
    let i = 0;
    for (const step of this.baseCategories) {
      this.translate.get('REGISTER_PAGE_REDESIGN.LOGIN_PASSWORD').subscribe((text: string) => {
        this.categories[i].name = text;
      });
      i++;
    }
  }
}
