import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {DashboardService} from "../api/calculations/dashboard";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-personalized-dietary-advice',
  templateUrl: './personalized-dietary-advice.component.html',
  styleUrls: ['./personalized-dietary-advice.component.css']
})
export class PersonalizedDietaryAdviceComponent implements OnInit {

  constructor(private translate: TranslateService, public dashboardService: DashboardService, private route: ActivatedRoute) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.changeText('[_index: 0]');
      this.updateChartLabels();
    });
  }

  pieChart: any;


  textBoxLeft;
  textBoxRight;
  textBoxTip;
  textBoxTipPersonalOne;
  textBoxTipPersonalTwo;
  textBoxTipPersonalThree;
  labelsNl = ['Graan en graanproducten', 'Fruit', 'Noten en zaden', 'Groenten', 'Zuivel', 'Oliën en boters', 'Vlees',
    'Zeevruchten', 'Peulvruchten', 'Water'];
  labelsEn = ['Grains', 'Fruits', 'Nuts and seeds', 'Vegetables', 'Dairy', 'Condiments (oils and butter)', 'Meat',
    'Seafood', 'Legumes', 'Water'];

  adjustText(tag: string) {

    this.translate.get('PERSONALIZED_DIETARY_ADVICES.' + tag + '_LEFT').subscribe((text: string) => {
      this.textBoxLeft = text;
    });
    this.translate.get('PERSONALIZED_DIETARY_ADVICES.' + tag + '_RIGHT').subscribe((text: string) => {
      this.textBoxRight = text;
    });
    this.translate.get('PERSONALIZED_DIETARY_ADVICES.' + tag + '_TIP').subscribe((text: string) => {
      this.textBoxTip = text;
    });

    this.translate.get('PERSONALIZED_DIETARY_ADVICES.' + tag + '_TIP_PERSONAL_ONE').subscribe((text: string) => {
      this.textBoxTipPersonalOne = text;
    });

    this.translate.get('PERSONALIZED_DIETARY_ADVICES.' + tag + '_TIP_PERSONAL_TWO').subscribe((text: string) => {
      this.textBoxTipPersonalTwo = text;
    });

    this.translate.get('PERSONALIZED_DIETARY_ADVICES.' + tag + '_TIP_PERSONAL_THREE').subscribe((text: string) => {
      this.textBoxTipPersonalThree = text;
    });
  }


  changeText(array) {
    switch (array[0]._index) {
      case 0: {
        this.adjustText('GRAINS');
        break;
      }
      case 1: {
        this.adjustText('FRUITS');
        break;
      }
      case 2: {
        this.adjustText('NUTS');
        break;
      }
      case 3: {
        this.adjustText('VEGETABLES');
        break;
      }
      case 4: {
        this.adjustText('DAIRY');
        break;
      }
      case 5: {
        this.adjustText('CONDIMENTS');
        break;
      }
      case 6: {
        this.adjustText('MEAT');
        break;
      }
      case 7: {
        this.adjustText('SEAFOOD');
        break;
      }
      case 8: {
        this.adjustText('LEGUMES');
        break;
      }
      case 9: {
        this.adjustText('WATER');
        break;
      }
      default: {
        this.adjustText('GRAINS');
        break;
      }
    }
  }

  async checkDiet() {
    const testKitID = Number(this.route.snapshot.paramMap.get('id'));
    const data = (await this.dashboardService.GetSurveyAnswers(testKitID, 19)).data;

    console.log(data);
    const section1 = [63];
    const section2 = [64, 65, 66, 67, 68, 69, 70];
    const section3 = [61, 62];

    const tip1 = document.getElementById('tip1') as HTMLCanvasElement;
    const tip2 = document.getElementById('tip2') as HTMLCanvasElement;
    const tip3 = document.getElementById('tip3') as HTMLCanvasElement;
    if (data[0]) {
      for (const i of data) {
        if (section1.includes(i)) {
          tip1.style.display = 'flex';
        }
        if (section2.includes(i)) {
          tip2.style.display = 'flex';
        }
        if (section3.includes(i)) {
          tip3.style.display = 'flex';
        }
      }
    } else {
      tip2.style.display = 'block';
    }
  }


  ngOnInit() {
    this.checkDiet();
    const canvasNutrient = document.getElementById('pie-chart') as HTMLCanvasElement;
    const ctxNutrient = canvasNutrient.getContext('2d');
    const that = this;
    this.pieChart = new Chart(ctxNutrient, {
      type: 'pie',
      data: {
        labels: ['Grains', 'Fruits', 'Nuts and seeds', 'Vegetables', 'Dairy', 'Condiments (oils and butter)',
          'Meat', 'Seafood', 'Legumes', 'Water'],
        datasets: [{
          label: 'Population (millions)',
          backgroundColor: ['#461862', '#5D2183', '#7429A3', '#8C31C4', '#9F4BD2', '#AD66D9', '#C28DE2', '#D3ADEB', '#E5CEF3', '#F6EFFB'],          data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10]
        }]
      },
      options: {
        onClick: function (event, elements) {
          that.changeText(elements);
        },
        title: {
          display: false
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: true
      }
    });
    this.updateChartLabels();
    this.changeText('GRAINS');
  }

  updateChartLabels() {
    if (this.translate.currentLang.toString() === 'nl') {
      this.pieChart.data.labels = this.labelsNl;
    } else {
      this.pieChart.data.labels = this.labelsEn;
    }
    this.pieChart.update();
  }
}
