import {Component, OnInit} from '@angular/core';
import {SubjectService} from '../api/test/subject';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ITestKitUpdate, IUserProfile} from 'interfaces';
import {ISubject} from '../../../interfaces/tests';

@Component({
  selector: 'app-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.css']
})
export class ResultsTableComponent implements OnInit {

  constructor(private subjectService: SubjectService, private router: Router, private route: ActivatedRoute,
              private formBuilder: FormBuilder) { }

   btnViewPersonalInfo = 'View personal information';
  btnAddKit = 'Add kit';
  btnFillIn = 'Fill in';
  btnShareAll = 'Share all';

  person: ISubject;
  subjectId: number;
  private sub: any;
  addKitForm: FormGroup;

  async ngOnInit() {
    this.subjectId = Number(this.route.snapshot.paramMap.get('id'));

    this.addKitForm = new FormGroup({
      kit_code: new FormControl(null, [Validators.required])
    });

    const response = await this.subjectService.GetSubject(this.subjectId);
    this.person = response.data;
  }

  async onSubmit() {
    const updateKitCode: ITestKitUpdate = {
      uid_testkit: this.addKitForm.get('kit_code').value,
      subject_id: this.subjectId
    };
    console.log(updateKitCode.subject_id);
    console.log(updateKitCode.uid_testkit);
    const response = await this.subjectService.LinkTestKit(updateKitCode, this.person.id);
    const response2 = await this.subjectService.GetSubject(this.subjectId);
    this.person = response2.data;
  }

}
