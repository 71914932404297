import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {IAddress, IOrder} from '../../../interfaces/orders';
import {OrdersService} from '../api/orders/orders';
import {TranslateService} from "@ngx-translate/core";
import {UtilityService} from "../utility.service";
import {UserService} from '../api/users/users';
import {AuthenticationService} from "../api/login/authentication";
import { IUserProfileCreate } from "interfaces";
import {UtilsService} from '../api/utils/utils';
import { AppComponent } from "../app.component";

declare var gtag: Function;
declare let dataLayer: any[];

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private orderService: OrdersService,
              private utils: UtilityService, public auth: AuthenticationService, private translate: TranslateService,
              private userService: UserService, private utilsAPI: UtilsService, 
              private app: AppComponent) {
  }

  shippingDetailsForm = this.formBuilder.group({
    registerPhoneNr: '',
    registerDoB: '',
    registerPassword: '',
    registerEmail: '',
    registerLastName: '',
    registerFirstName: '',
    shippingStreetName: '',
    shippingStreetNumber: '',
    shippingZipCode: '',
    shippingCity: '',
    shippingCountry: '',
    shippingStreetExtension: '',
    billingStreetName: '',
    billingStreetNumber: '',
    billingZipCode: '',
    billingCity: '',
    billingCountry: '',
    billingStreetExtension: ''
  });

  title = 'Fill in your payment details';
  btnCheck = 'Same as shipping';
  lblShipping = 'Shipping address';
  lblBilling = 'Billing address';
  checked = true;
  billingRequired = true;
  order: IOrder;

  ngOnInit() {
    document.getElementById('billing').style.display = 'none';
    this.billingRequired = false;

    if (localStorage.getItem('Basket')) {
      this.order = JSON.parse(localStorage.getItem('Basket'));
    }

    if (this.auth.isAuthenticated()) {
      const div = document.getElementById('registerBox');
      div.style.display = 'none';
    }
    console.log(this.order);

    dataLayer.push({  ecommerce: null});

      dataLayer.push({
        
        event: "view_cart",
        ecommerce: {
          currency: "EUR",
          value: this.order.total_price,
          items: [
            {
              item_id: '1',
              item_name: "Microbiome test kit",
              price: 185,
              quantity: this.order.total_price / 185
            }
          ]
        }
      });
  }

  public SetBilling(evt) {
    this.checked = evt.target.checked;
    if (this.checked) {
      document.getElementById('billing').style.display = 'none';
      this.billingRequired = false;
    } else {
      document.getElementById('billing').style.display = 'block';
      this.billingRequired = true;
    }
  }

  async onSubmit() {
    let isEmailTaken = null;
    let isPhoneNumberTaken = null;

    let totalPrice = this.order.total_price
    
    

    if (!this.auth.isAuthenticated()) {
      if (this.checkRegistrationDetailsMissing()){
        this.utils.showWarning('PAYMENT_USER_REGISTRATION_ERROR', 'PAYMENT_USER_REGISTRATION_ERROR')
        return;
      }

      await this.userService
        .IsEmailTaken(this.shippingDetailsForm.get("registerEmail").value)
        .then((data) => (isEmailTaken = data.data));

      if (isEmailTaken) {
        this.utils.showWarning("EMAIL_IN_USE_WARNING", "EMAIL_IN_USE_WARNING")
        return;
      }

      await this.userService
        .IsPhoneTaken(this.shippingDetailsForm.get("registerPhoneNr").value)
        .then((data) => (isPhoneNumberTaken = data.data));

      if (isPhoneNumberTaken) {
        this.utils.showWarning('PHONE_NUMBER_IN_USE_WARNING', 'PHONE_NUMBER_IN_USE_WARNING')
        return;
      }
      if (this.shippingDetailsForm.get("registerPassword").value.length < 8) {
        this.utils.showWarning("SHORT_PW_WARNING", "SHORT_PW_WARNING");
        return;
      }
      if (this.shippingDetailsForm.get("registerPassword").value.length > 20) {
        this.utils.showWarning("LONG_PW_WARNING", "LONG_PW_WARNING");
        return;
      }
    }
      
    const shippingAddress: IAddress = {
      country: this.shippingDetailsForm.get('shippingCountry').value,
      city: this.shippingDetailsForm.get('shippingCity').value,
      street_name: this.shippingDetailsForm.get('shippingStreetName').value,
      street_number: this.shippingDetailsForm.get('shippingStreetNumber').value,
      street_number_extension: this.shippingDetailsForm.get('shippingStreetExtension').value,
      zip_code: this.shippingDetailsForm.get('shippingZipCode').value
    };

    this.order.shipping_address_obj = shippingAddress;
    this.order.billing_address_obj = shippingAddress;

    if (this.billingRequired) {

      const billingAddress: IAddress = {
        country: this.shippingDetailsForm.get('shippingCountry').value,
        city: this.shippingDetailsForm.get('shippingCity').value,
        street_name: this.shippingDetailsForm.get('shippingStreetName').value,
        street_number: this.shippingDetailsForm.get('shippingStreetNumber').value,
        street_number_extension: this.shippingDetailsForm.get('shippingStreetExtension').value,
        zip_code: this.shippingDetailsForm.get('shippingZipCode').value
      };

      this.order.billing_address_obj = billingAddress;
    }

    if (this.checkOrderDetailsEmpty(this.order)) {
      this.utils.showWarning('ORDER_DETAILS_ERROR', 'ORDER_DETAILS_ERROR');
      return;
    } else {

      // gtag('event', 'pre-purchase', {'currency': 'EUR','value': totalPrice});

      try {

        if (!this.auth.isAuthenticated()) {
          const newUser: IUserProfileCreate = {
            first_name: this.shippingDetailsForm.get("registerFirstName").value,
            last_name: this.shippingDetailsForm.get("registerLastName").value,
            email: this.shippingDetailsForm.get("registerEmail").value.toLowerCase(),
            date_of_birth: this.shippingDetailsForm.get("registerDoB").value,
            phone_number: this.shippingDetailsForm.get("registerPhoneNr").value,
            password: this.shippingDetailsForm.get("registerPassword").value,
            is_medical_user: false,
            address: shippingAddress,
          };

          const user = await this.userService.createAccount(newUser, localStorage.getItem("language"), this.app.partnerVersion);
          const response = await this.orderService.PlaceOrder(this.order, user.data.id);
          window.location.href = response.data as string;

          dataLayer.push({
        
            event: "add_payment_info",
            ecommerce: {
              currency: "EUR",
              value: this.order.total_price,
              items: [
                {
                  item_id: '1',
                  item_name: "Microbiome test kit",
                  price: 185,
                  quantity: this.order.total_price / 185
                }
              ]
            }
          });

          window.open(String(response.data), '_blank');
          //localStorage.removeItem('Basket');

        } else {
          const user = await this.userService.GetUser();
          const response = await this.orderService.PlaceOrder(this.order, user.data.id);
          window.location.href = response.data as string;

          dataLayer.push({
        
            event: "add_payment_info",
            ecommerce: {
              currency: "EUR",
              value: this.order.total_price,
              items: [
                {
                  item_id: '1',
                  item_name: "Microbiome test kit",
                  price: 185,
                  quantity: this.order.total_price / 185
                }
              ]
            }
          });

          window.open(String(response.data), '_blank');
          //localStorage.removeItem('Basket');
        }
      } catch {
        this.utils.showError('ORDER_ERROR', 'ORDER_ERROR');
      }
    }
  }

  checkRegistrationDetailsMissing(){
    if (!this.shippingDetailsForm.get("registerFirstName").value) {
      return true;
    }
    if (!this.shippingDetailsForm.get("registerLastName").value) {
      return true;
    }
    if (!this.shippingDetailsForm.get("registerEmail").value) {
      return true;
    }
    if (!this.shippingDetailsForm.get("registerPhoneNr").value) {
      return true;
    }
    if (!this.shippingDetailsForm.get("registerPassword").value) {
      return true;
    }
    if (!this.shippingDetailsForm.get("registerDoB").value) {
      return true;
    }
    return false;
  }

  checkOrderDetailsEmpty(order: IOrder){
    if (!order.billing_address_obj.country) {
      return true;
    }
    if (!order.billing_address_obj.city) {
      return true;
    }
    if (!order.billing_address_obj.street_name) {
      return true;
    }
    if (!order.billing_address_obj.street_number) {
      return true;
    }
    if (!order.billing_address_obj.zip_code) {
      return true;
    }

    if (!order.shipping_address_obj.country) {
      return true;
    }
    if (!order.shipping_address_obj.city) {
      return true;
    }
    if (!order.shipping_address_obj.street_name) {
      return true;
    }
    if (!order.shipping_address_obj.street_number) {
      return true;
    }
    if (!order.shipping_address_obj.zip_code) {
      return true;
    }
    return false;
  }
}
