import { HtmlTagDefinition } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import { RichTextFieldReadError } from "pdf-lib";
import { setMaxListeners } from "process";
import { pipe } from "rxjs";
import { INutrientAdvice } from "../../../interfaces/advice";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-target-values",
  templateUrl: "./target-values.component.html",
  styleUrls: ["./target-values.component.css"],
})
export class TargetValuesComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    public dietaryDashService: DietaryDashService,
    public app: AppComponent,
    private route: ActivatedRoute,
  ) {}

  doughnutChart: any;

  carbohydratesValue = 5;
  carbohydratesValueRanges: any = [];
  fatValue = 4;
  fatValueRanges: any = [];
  saturatedFatValue = 3;
  saturatedFatValueRanges: any = [];

  proteineObject: INutrientAdvice;
  saltObject: INutrientAdvice;
  fibreObject: INutrientAdvice;
  carbohydratesObject: INutrientAdvice;
  fatObject: INutrientAdvice;
  satFatObject: INutrientAdvice;

  fiberChartValues;
  fiberValue;
  saltChartValues;

  saturatedFatTitle = "";
  fatTitle = "";
  carbsTitle = "";

  sectionToDisplay: any = 0;

  nutrientValues: INutrientAdvice[];

  // fiber intake values
  lowerFiber = 10;
  mediumFiber = 10;
  higherFiber = 10;

  // salt intake values
  lowerSalt = 0;
  mediumSalt = 6;
  higherSalt = 4;

  partnerImgSrc = "";
  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];

  explanationText = "TARGET_NUTRIENTS.EXPLANATION_TEXT";

  calculateDietaryFiberChart(fiberObject: INutrientAdvice) {
    if (fiberObject.value < 20) {
      this.lowerFiber = 30;
    }
    if (fiberObject.value > 40) {
      this.higherFiber =
        (fiberObject.value % 10 === 0
          ? fiberObject.value
          : fiberObject.value - (fiberObject.value % 5) + 5) -
        this.mediumFiber -
        this.higherFiber;
    }

    const total = this.lowerFiber + this.mediumFiber + this.higherFiber;

    // this.fiberChartValues = [
    //   (this.lowerFiber / total) * 100 + "%",
    //   (this.mediumFiber / total) * 100 + "%",
    //   (this.higherFiber / total) * 100 + "%",
    // ];
    this.fiberChartValues = [
      fiberObject.range[0] + "%",
      fiberObject.range[1] - fiberObject.range[0] + "%",
      100 - fiberObject.range[1] + "%",
    ];

    console.log(fiberObject)
  }

  calculateSaltChart(saltObject: INutrientAdvice) {
    if (saltObject.value > 6) {
      this.higherSalt =
        (saltObject.value % 5 === 0
          ? saltObject.value
          : saltObject.value - (saltObject.value % 5) + 5) - this.mediumSalt;
    }

    const total = this.lowerSalt + this.mediumSalt + 4;

    console.log(this.saltObject.range);
    this.saltChartValues = [
      saltObject.range[1] * 10 + "%",
      (10 - saltObject.range[1]) * 10 + "%",
    ];
    console.log(this.saltChartValues);
  }

  async getNewGaugeNames() {
    this.translate
      .get("TARGET_NUTRIENTS.SATURATED")
      .subscribe((text: string) => {
        this.saturatedFatTitle = text;
      });
    this.translate.get("TARGET_NUTRIENTS.FAT").subscribe((text: string) => {
      this.fatTitle = text;
    });
    this.translate.get("TARGET_NUTRIENTS.CARBS").subscribe((text: string) => {
      this.carbsTitle = text;
    });
  }

  async createGauges() {
    console.log(this.carbohydratesValueRanges);
    const confCarbohydrates = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: this.carbohydratesValueRanges,
            value: this.carbohydratesValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.carbsTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const confFat = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: this.fatValueRanges,
            value: this.fatValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.fatTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const confSaturatedFat = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: this.saturatedFatValueRanges,
            value: this.saturatedFatValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.saturatedFatTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const canvasCarbohydrates = document.getElementById(
      "carbohydrates",
    ) as HTMLCanvasElement;
    const ctxCarbohydrates = canvasCarbohydrates.getContext("2d");

    const canvasFat = document.getElementById("fat") as HTMLCanvasElement;
    const ctxFat = canvasFat.getContext("2d");

    const canvasSaturatedFat = document.getElementById(
      "saturated_fat",
    ) as HTMLCanvasElement;
    const ctxSaturatedFat = canvasSaturatedFat.getContext("2d");

    this.doughnutChart = new Chart(ctxCarbohydrates, confCarbohydrates);

    this.doughnutChart = new Chart(ctxFat, confFat);

    this.doughnutChart = new Chart(ctxSaturatedFat, confSaturatedFat);
  }

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
      this.partnerImgSrc = "-" + this.app.partnerVersion;
      this.hideNonPartnerElements();
      this.explanationText =
        "TARGET_NUTRIENTS.EXPLANATION_TEXT_" +
        this.app.partnerVersion.toUpperCase();
    }

    const testKitID = Number(this.route.snapshot.paramMap.get("id"));
    if (sessionStorage.getItem("TargetNutValues-" + testKitID)) {
      this.nutrientValues = JSON.parse(
        sessionStorage.getItem("TargetNutValues-" + testKitID),
      );
    } else {
      this.nutrientValues = await (
        await this.dietaryDashService.GetNutrientAdvice(testKitID)
      ).data;
    }

    this.carbohydratesValue = this.nutrientValues.find(
      (x) => x.type === "carbohydrates",
    ).value;
    this.carbohydratesValueRanges = this.nutrientValues.find(
      (x) => x.type === "carbohydrates",
    ).range;
    this.carbohydratesValueRanges.push(80);
    console.log(this.carbohydratesValueRanges);

    this.fatValue = this.nutrientValues.find((x) => x.type === "fat").value;
    this.fatValueRanges = this.nutrientValues.find(
      (x) => x.type === "fat",
    ).range;
    this.fatValueRanges.push(80);

    this.saturatedFatValue = this.nutrientValues.find(
      (x) => x.type === "saturated_fat",
    ).value;
    this.saturatedFatValueRanges = this.nutrientValues.find(
      (x) => x.type === "saturated_fat",
    ).range;
    this.saturatedFatValueRanges.push(80);

    this.proteineObject = this.nutrientValues.find((x) => x.type === "protein");
    this.saltObject = this.nutrientValues.find((x) => x.type === "salt");
    this.saltObject.value = Math.max(0.5, Math.min(this.saltObject.value, 9.5));
    this.fibreObject = this.nutrientValues.find((x) => x.type === "fibers");
    this.fatObject = this.nutrientValues.find((x) => x.type === "fat");
    this.satFatObject = this.nutrientValues.find(
      (x) => x.type === "saturated_fat",
    );
    this.carbohydratesObject = this.nutrientValues.find(
      (x) => x.type === "carbohydrates",
    );

    // Prevent gauge values from going over 100
    if (this.carbohydratesValue > 100) this.carbohydratesValue = 100;
    if (this.fatValue > 100) this.fatValue = 100;
    if (this.saturatedFatValue > 100) this.saturatedFatValue = 100;

    this.calculateDietaryFiberChart(
      this.nutrientValues.find((x) => x.type === "fibers"),
    );
    this.calculateSaltChart(this.nutrientValues.find((x) => x.type === "salt"));

    this.fiberValue = this.getDietaryFiberLevel();

    // TODO when changing language update values
    this.translate
      .get("TARGET_NUTRIENTS.SATURATED")
      .subscribe((text: string) => {
        this.saturatedFatTitle = text;
        const confSaturatedFat = {
          type: "gauge",
          data: {
            datasets: [
              {
                data: this.saturatedFatValueRanges,
                value: this.saturatedFatValue,
                backgroundColor: [
                  this.selectedColors["bad"],
                  this.selectedColors["great"],
                  this.selectedColors["bad"],
                ],
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            title: {
              display: true,
              text: this.saturatedFatTitle,
            },
            layout: {
              padding: {
                bottom: 30,
              },
            },
            needle: {
              // Needle circle radius as the percentage of the chart area width
              radiusPercentage: 2,
              // Needle width as the percentage of the chart area width
              widthPercentage: 3.2,
              // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
              lengthPercentage: 80,
              // The color of the needle
              color: "rgba(0, 0, 0, 1)",
            },
            valueLabel: {
              display: false,
              formatter: Math.round,
            },
          },
        };

        const canvasSaturatedFat = document.getElementById(
          "saturated_fat",
        ) as HTMLCanvasElement;
        const ctxSaturatedFat = canvasSaturatedFat.getContext("2d");

        this.doughnutChart = new Chart(ctxSaturatedFat, confSaturatedFat);
      });

    this.translate.get("TARGET_NUTRIENTS.FAT").subscribe((text: string) => {
      this.fatTitle = text;
      const confFat = {
        type: "gauge",
        data: {
          datasets: [
            {
              data: this.fatValueRanges,
              value: this.fatValue,
              backgroundColor: [
                this.selectedColors["bad"],
                this.selectedColors["great"],
                this.selectedColors["bad"],
              ],
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.fatTitle,
          },
          layout: {
            padding: {
              bottom: 30,
            },
          },
          needle: {
            // Needle circle radius as the percentage of the chart area width
            radiusPercentage: 2,
            // Needle width as the percentage of the chart area width
            widthPercentage: 3.2,
            // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
            lengthPercentage: 80,
            // The color of the needle
            color: "rgba(0, 0, 0, 1)",
          },
          valueLabel: {
            display: false,
            formatter: Math.round,
          },
        },
      };

      const canvasFat = document.getElementById("fat") as HTMLCanvasElement;
      const ctxFat = canvasFat.getContext("2d");

      this.doughnutChart = new Chart(ctxFat, confFat);
    });

    this.translate.get("TARGET_NUTRIENTS.CARBS").subscribe((text: string) => {
      this.carbsTitle = text;
      const confCarbohydrates = {
        type: "gauge",
        data: {
          datasets: [
            {
              data: this.carbohydratesValueRanges,
              value: this.carbohydratesValue,
              backgroundColor: [
                this.selectedColors["bad"],
                this.selectedColors["great"],
                this.selectedColors["bad"],
              ],
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.carbsTitle,
          },
          layout: {
            padding: {
              bottom: 30,
            },
          },
          needle: {
            // Needle circle radius as the percentage of the chart area width
            radiusPercentage: 2,
            // Needle width as the percentage of the chart area width
            widthPercentage: 3.2,
            // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
            lengthPercentage: 80,
            // The color of the needle
            color: "rgba(0, 0, 0, 1)",
          },
          valueLabel: {
            display: false,
            formatter: Math.round,
          },
        },
      };

      const canvasCarbohydrates = document.getElementById(
        "carbohydrates",
      ) as HTMLCanvasElement;
      const ctxCarbohydrates = canvasCarbohydrates.getContext("2d");

      this.doughnutChart = new Chart(ctxCarbohydrates, confCarbohydrates);
    });

    const coll = document.getElementsByClassName("collapsible");
    for (let i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        const content = this.nextElementSibling as HTMLElement;
        if (this.classList.contains("active")) {
          this.classList.remove("active");
          content.style.maxHeight = null;
        } else {
          let c;
          for (c = 0; c < coll.length; c++) {
            coll[c].classList.remove("active");
            const nextElmnt = coll[c].nextElementSibling as HTMLElement;
            nextElmnt.style.maxHeight = null;
          }
          this.classList.add("active");
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  }

  getDietaryFiberLevel() {
    const fiberValue = this.nutrientValues.find((x) => x.type === "fibers");
    if (fiberValue.value > 98) {
      fiberValue.value = 98;
    }
    if (fiberValue.value < 2) {
      fiberValue.value = 2
    }
    return "calc(" + fiberValue.value + "% - 15px";
  }

  getSaltLevel() {
    const saltValue = this.nutrientValues.find((x) => x.type === "salt");
    const ref = this.mediumSalt + this.higherSalt;
    let cal = (saltValue.value / ref) * 100;

    console.log(saltValue.value)

    if (cal > 100) {
      cal = 95;
    }

    if (cal < 0) {
      cal = 5;
    }

    return "calc(" + cal + "% - 15px)";
  }

  hideNonPartnerElements() {
    let elements = Array.from(
      document.getElementsByClassName("none-partner-elements"),
    );
    for (const element of elements) {
      element.setAttribute("style", "display: none");
    }
  }

  toggleSectionToDisplay(section: any) {
    this.sectionToDisplay == section
      ? (this.sectionToDisplay = 0)
      : (this.sectionToDisplay = section);
  }
}
