import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { IOrder } from "../../interfaces/orders";
import { AuthenticationService } from "./api/login/authentication";
import { UserService } from "./api/users/users";
import { ThemeService } from "./theme";

declare var gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "microbiome";
  activePage = "home";

  shoppingCartNumber = this.updateCartNumber();
  basket: IOrder;
  selectedLanguage: string;
  userToken: string;
  partnerVersion: string = "";

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    public auth: AuthenticationService,
    private router: Router,
    private themeService: ThemeService
  ) {
    if (
      window.location.hostname == "iprobio.gutexplorer.com" || 
      window.location.hostname == "www.iprobio.gutexplorer.com" || 
      window.location.hostname == "" || 
      window.location.hostname == "test.gutexplorer.com"
    ) {
      translate.setDefaultLang("sk");
      translate.use("sk");
      localStorage.setItem("language", "sk");
      this.selectedLanguage = "sk";

    } else if (localStorage.getItem("language")) {
      translate.setDefaultLang(localStorage.getItem("language"));
      translate.use(localStorage.getItem("language"));
      this.selectedLanguage = localStorage.getItem("language");
      
    } else {
      translate.setDefaultLang("nl");
      translate.use("nl");
      localStorage.setItem("language", "nl");
      this.selectedLanguage = "nl";
    }

    const navEndEvent$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag("config", "GTM-NCN2T3P", { page_path: e.urlAfterRedirects });
      gtag("config", "G-8CVL1B8WXC", { page_path: e.urlAfterRedirects });
    });
  }

  homeUrl: string;

  navLinks = {
    iprobio: {
      testResults: "/vysledky",
      passwordRecovery: "/obnova-hesla",
      accountCreation: "/vytvorit-ucet/customer",
      accountDetails: "/detaily-účtu",
      results: "/ucet/1",
      newSubject: "/nový-užívateľ",
      foodDiary: "/stravovací-denník",
      foodDiaryExample: "/príklad-potravinového-denníka",
      survey: "/prieskum",      
      surveyAnswers: "/odpovede-prieskumu",
      dashboard: "/moje-výsledky/",
      diversity: "/diverzita-baktérií",
      targetValues: "/cieľové-hodnoty-živín",
      gutHealth: "/podkategória",
      levels: "/hladiny-baktérií",
      takeHomeMessage: "/zhrnutie",
      subjectDetails: "/detaily-pacienta",
      supplementAdvice: "/supplement-advice"
    },
    default: {
      testResults: "/test-results",
      passwordRecovery: "/password-recovery",
      accountCreation: "/create-account/customer",
      accountDetails: "/account-details",
      results: "/account-category/1",
      newSubject: "/new-user",
      foodDiary: "/food-diary",
      foodDiaryExample: "/food-diary-example",
      survey: "/survey",
      surveyAnswers: "/survey-answers",
      dashboard: "/dashboard/",
      diversity: "/bacteria-diversity",
      targetValues: "/target-nutrient-values",
      gutHealth: "/subcategory",
      levels: "/bacteria-levels",
      takeHomeMessage: "/take-home-message",
      subjectDetails: "/subject-details",
      supplementAdvice: "/supplement-advice"
    }
  }

  activeLinks = this.navLinks['default']

  ngOnInit() {
    this.homeUrl = "https://innerbuddies.com/";
    if (
      window.location.hostname == "iprobio.gutexplorer.com" || 
      window.location.hostname == "www.iprobio.gutexplorer.com" || 
      window.location.hostname == "" || 
      window.location.hostname == "test.gutexplorer.com"
    ) {
      this.themeService.setTheme("iprobio");
      this.selectCorrectNavDesign();
      this.partnerVersion = "iprobio";
      document.getElementById("iprobioContact").style.display = "flex";
      this.homeUrl = "https://iprobio.gutexplorer.com/ucet/1";
      document
        .querySelector("#appIcon")
        .setAttribute(
          "href",
          "./assets/favicons/" + this.partnerVersion + ".png"
        );
      this.activeLinks = this.navLinks.iprobio
    }
    // Just in case
    this.selectCorrectNavDesign();
    this.hideNonPartnerElements();
    
    // this.userToken = this.readCookie("token");
    // if (this.userToken != "") {
    //   localStorage.setItem("token", this.userToken);
    // } else {
    //   this.router.navigate(["not-logged-in"]);
    // }

    this.hideLogin();
  }

  updateCartNumber() {
    if (localStorage.getItem("Basket")) {
      this.basket = JSON.parse(localStorage.getItem("Basket"));
      let total = 0;
      if (!this.basket.order_items) return false;
      for (const item of this.basket.order_items) {
        total += item.quantity;
      }
      localStorage.setItem("shoppingCartNumber", total.toString());
      return total.toString();
    }
    return "0";
  }

  onActivate(event) {
    // window.scroll(0,0);

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  /**this.toggleHome = !this.toggleHome;
   this.toggleTestResults = !this.toggleTestResults;
   this.toggleStart = !this.toggleStart;
   this.toggleInfo = !this.toggleInfo;
   this.toggleAccount = !this.toggleAccount;  **/

  async changeLanguage(reload: boolean) {
    this.translate.setDefaultLang(this.selectedLanguage);
    this.translate.use(this.selectedLanguage);
    localStorage.setItem("language", this.selectedLanguage);
    let languageCodeId = 1;
    if (this.selectedLanguage === "nl") {
      languageCodeId = 2;
    }
    if (this.selectedLanguage === "it") {
      languageCodeId = 3;
    }
    if (this.selectedLanguage === "sk") {
      languageCodeId = 4;
    }
    if (this.selectedLanguage === "de") {
      languageCodeId = 6;
    }
    await await this.userService.UpdateUserLanguage(languageCodeId);
    if (reload) {
      window.location.reload();
    }
  }

  hideLogin() {
    if (this.partnerVersion == 'iprobio') {
      if (!this.auth.isAuthenticated()) {
        console.log('test')
        document.getElementById("profile-tab-iprobio")!.style.display = "none";
      } else {
        document.getElementById("profile-tab-iprobio")!.style.display = "";
      }
    } else {
      if (!this.auth.isAuthenticated()) {
        document.getElementById("profile-tab-default")!.style.display = "none";
        document.getElementById("login-tab-default")!.style.display = "";
      } else {
        document.getElementById("profile-tab-default")!.style.display = "";
        document.getElementById("login-tab-default")!.style.display = "none";
      }
    }
  }

  readCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  hideNonPartnerElements() {
    let elements = Array.from(
      document.getElementsByClassName("none-partner-elements")
    );
    console.log(elements);
    for (const element of elements) {
      element.setAttribute("style", "display: none");
    }
  }

  selectCorrectNavDesign() {
    if (this.partnerVersion == 'iprobio') {
      document.getElementById("profile-tab-default")!.style.display = "none";
      document.getElementById("login-tab-default")!.style.display = "none";
      document.getElementById("language")!.style.display = "none";
    } else {
      document.getElementById("profile-tab-iprobio")!.style.display = "none";
    }
  }

  logOut() {
    this.auth.logout();
    this.hideLogin();
    this.selectCorrectNavDesign();
  }
}
