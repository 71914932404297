import {Component, OnInit} from '@angular/core';
import * as Survey from 'survey-angular';
import {SurveyService} from '../api/survey/survey';
import {ActivatedRoute, Router} from '@angular/router';
import {SubjectService} from '../api/test/subject';
import {IUserSurveyAnswerText} from '../../../interfaces/surveys';
import {ITestKit} from '../../../interfaces/tests';
import { AppComponent } from "../app.component";

@Component({
  selector: 'survey',
  templateUrl: './survey-answers.component.html',
  styleUrls: ['./survey-answers.component.css']
})
export class SurveyAnswersComponent implements OnInit {

  constructor(
    private surveyService: SurveyService, 
    private testkitService: SubjectService,
    private route: ActivatedRoute,
    public app: AppComponent,
  ) {
  }

  userSurveyAnswers: IUserSurveyAnswerText[];
  testKitID: number;
  testKitStatus: string='finished';

  async ngOnInit() {
    try {
      this.testKitID = Number(this.route.snapshot.paramMap.get("id"));
      this.userSurveyAnswers = this.mergeAnswers((await this.surveyService.GetUserServeyAnswers(this.testKitID)).data);
      let testKit = (await this.testkitService.GetTestKit(this.testKitID)).data;
      this.testKitStatus = testKit.status;
    } catch {
      console.log("error");
    }
  }

  mergeAnswers(answers: IUserSurveyAnswerText[]) {
    let uniqueQuestions = [];
    let filteredUserAnswers = [];
    let duplicateUserAnswers = [];
    for(let i = 0; i < answers.length; i++) {
      if (!uniqueQuestions.includes(answers[i].question_id)) {
        uniqueQuestions.push(answers[i].question_id);
        filteredUserAnswers.push(answers[i]);
      } else {
        duplicateUserAnswers.push(answers[i])
      }
    }

    for (let x = 0; x < filteredUserAnswers.length; x++) {
      for(let y = 0; y < duplicateUserAnswers.length; y++) {
        if (filteredUserAnswers[x].question_id == duplicateUserAnswers[y].question_id) {
          if (filteredUserAnswers[x].answer_text != duplicateUserAnswers[y].answer_text) {
            filteredUserAnswers[x].answer_text = filteredUserAnswers[x].answer_text.concat(', ' + duplicateUserAnswers[y].answer_text)
          }
        }
      }
    }
    return filteredUserAnswers;
  }

}
