import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {IAddress, IOrder} from '../../../interfaces/orders';
import {UtilsService} from '../api/utils/utils';

declare var gtag: Function;
declare var fbq: Function;
declare let dataLayer: any[];

@Component({
  selector: 'app-successful-payment',
  templateUrl: './successful-payment.component.html',
  styleUrls: ['./successful-payment.component.css']
})
export class SuccessfulPaymentComponent implements OnInit {

  constructor(private utilsAPI: UtilsService, private route: ActivatedRoute) { }

  order: IOrder;

  ngOnInit() {

    if (localStorage.getItem('Basket')) {
      this.order = JSON.parse(localStorage.getItem('Basket'));
    }

    let totalPrice = this.order.total_price
    console.log(totalPrice);
    // gtag('event', 'purchase', {'currency': 'EUR','value': totalPrice});

    dataLayer.push({
        
      event: "purchase",
      ecommerce: {
        currency: "EUR",
        value: this.order.total_price,
        items: [
          {
            item_id: '1',
            item_name: "Microbiome test kit",
            price: 185,
            quantity: this.order.total_price / 185
          }
        ]
      }
    });

    let timeStamp = Math.round(Date.now() / 1000);

    let facebookPixlData = {
      "data": [
          {
              "event_name": "Purchase",
              "event_time": timeStamp,
              "action_source": "website",
              "user_data": {
                  "em": [
                      "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
                  ],
                  "ph": [
                      null
                  ]
              },
              "custom_data": {
                  "currency": "EUR",
                  "value": totalPrice.toString
              }
          }
      ]
  }

    // this.utilsAPI.FacebookTag(facebookPixlData);
    fbq('track', 'Purchase', {currency: "EUR", value: totalPrice})

    localStorage.removeItem('Basket');
  }

  returnHome() {
    window.location.href = '/';
  }

}
