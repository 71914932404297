import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../api/login/authentication';
import {UtilityService} from '../utility.service';
import {TranslateService} from "@ngx-translate/core";
import {AppComponent} from '../app.component';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private translate: TranslateService, private authenticationService: AuthenticationService, private utils: UtilityService, private router: Router, private route: ActivatedRoute, public app: AppComponent) {
    this.translate.get('REGISTER_PAGE_REDESIGN.LOGIN_PASSWORD').subscribe((text: string) => {
      this.passwordFieldText = text;
    });
  }

  resetForm: FormGroup;
  token: string;
  private sub: any;
  passwordFieldText: string;

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.token = params.token;
    });
    this.resetForm = new FormGroup({
      password1: new FormControl(null, [Validators.required]),
      password2: new FormControl(null, [Validators.required])
    });
  }

  async resetPassword() {
    if (this.token) {
      try{
        const response = await this.authenticationService.passwordReset(this.resetForm.get('password1').value, this.token);
        if (this.app.partnerVersion != 'default') {
          await this.router.navigate(['/test-results']);
        } else {
          window.location.href = 'https://www.innerbuddies.com/account/login';
        }
      } catch (err) {
        console.log(err.response.data.detail);
        if (err.response.data.detail === 'Password does not meet requirements'){
          this.utils.showWarning('SHORT_PW_WARNING', 'SHORT_PW_WARNING');
        }
      }
    } else {
      console.log('no');
    }
  }

}
