import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';
import {IDashboardDietary, IEyyo} from '../../../interfaces/advice';
import {DietaryDashService} from '../api/calculations/dietarydash';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-healthy-microorganisms',
  templateUrl: './healthy-microorganisms.component.html',
  styleUrls: ['./healthy-microorganisms.component.css']
})
export class HealthyMicroorganismsComponent implements OnInit {

  constructor(public dietaryDashService: DietaryDashService, private route: ActivatedRoute, private translate: TranslateService) {
  }

  boxplotChart: any;
  normalrange = 'normal range - personalized according to your age, gender and health status; sometimes, your bacterium level is outside of the normal range, but it is still considered healthy';
  badrange = 'bad - your buddies need your attention';
  okayrange = 'okay - you’re doing okay but your buddies could use some help';
  greatrange = 'great - you’re the biggest supporter of your inner buddies!';

  bacteria = [
    {
      id: 1,
      name: 'Bifidobacterium',
      description: 'Your buddies need your attention!'
    },
    {
      id: 2,
      name: 'Akkermansia',
      description: 'You are doing okay, but your buddies could use some help.'
    },
    {
      id: 3,
      name: 'Lactobacillus',
      description: 'You are doing okay, but your buddies could use some help.'
    },
    {
      id: 4,
      name: 'Faecalibacterium',
      description: 'You are the biggest supporter of your inner buddies!'
    }
  ];

  // title2 = "Bifidobacterium";
  // info2 = "Your buddies need your attention!";
  // title3 = "Akkermansia";
  // info3 = "You're doing okay, but your buddies could use some help.";
  // title4 = "Lactobacillus";
  // info4 = "You're doing okay, but your buddies could use some help.";
  // title5 = "Faecalibacterium";
  // info5 = "You're the biggest supporter of your inner buddies!";

  healthyMicrobs;
  testKitID;
  range;
  dashBacteria: IEyyo;
  dietaryAdvice: IDashboardDietary[];
  faecalibacteriumWarning = false;
  lactobacillusWarning = false;
  akkermansiaWarning = false;
  bifidobacteriumWarning = false;

  async ngOnInit() {
    this.dashBacteria = JSON.parse(sessionStorage.getItem('Dashboard'));
    console.log(this.dashBacteria);
    this.healthyMicrobs = this.dashBacteria.graphs.find(x => x.graph === 'Presence Of').value;
    this.range = this.dashBacteria.graphs.find(x => x.graph === 'Presence Of').range;
    this.testKitID = Number(this.route.snapshot.paramMap.get('id'));
    this.dietaryAdvice = await (await this.dietaryDashService.GetDietaryAdvice(this.testKitID, 'Presence of healthy microorganisms')).data;

    this.faecalibacteriumWarning = this.isWarning(this.dashBacteria.top35.find(x => x.bacteria === 'Faecalibacterium'));
    this.lactobacillusWarning = this.isWarning(this.dashBacteria.top35.find(x => x.bacteria === 'Lactobacillus'));
    this.akkermansiaWarning = this.isWarning(this.dashBacteria.top35.find(x => x.bacteria === 'Akkermansia'));
    this.bifidobacteriumWarning = this.isWarning(this.dashBacteria.top35.find(x => x.bacteria === 'Bifidobacterium'));

    function randomValues(count, min, max) {
      const delta = max - min;
      return Array.from({length: count}).map(() => Math.random() * delta + min);
    }

    const boxplotData = {
      // define label tree
      labels: [''],
      datasets: [{
        label: 'Dataset 1',
        backgroundColor: '#A0939C',
        borderColor: '#3C1642',
        borderWidth: 2,
        outlierColor: '#999999',
        padding: 0,
        itemRadius: 0,
        data: [
          [100, 10, 10, 10, 10, 10, 100]
        ]
      }]
    };
    window.onload = () => {
      // const ctx = document.getElementById("canvas").getContext("2d");
      // first issue
      const canvass = document.getElementById('canvas') as HTMLCanvasElement;
      const ctxx = canvass.getContext('2d');
      // second issue
      this.boxplotChart = new Chart(ctxx, {
        type: 'boxplot',
        data: boxplotData,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false,
          },
          plugins: {},
          scales: {
            // error 1 (fixed)
            yAxes: [{
              display: false,
            }],
            xAxes: [{
              display: false
            }]
          },
          title: {
            display: false,
            text: 'Chart.js Box Plot Chart'
          }
        }
      });

    };
  }

  getBacteriaLevel() {
    const dif = (this.range[1] - this.range[0]) / 70 * 15;
    const refUp = this.range[1] + dif; // potential for gas production
    const refLow = this.range[0] - dif; // potential for gas production

    let cal = (this.healthyMicrobs - refLow) / (refUp - refLow) * 100;
    if (cal < 0) {
      cal = 0;
    }
    if (cal > 100) {
      cal = 100;
    }
    return 'calc(' + cal + '% - 15px)';
  }

  resultSampleText() {
    if (this.healthyMicrobs < this.range[0]) {
      return 'be_aware';
    }
    if (this.healthyMicrobs > this.range[1]) {
      return 'great';
    }
    return 'normal';
  }

  isWarning(bacteria: any): boolean {
    return bacteria && bacteria.range[0] < bacteria.value < bacteria.range[1];
  }

  isDisabled(bacteriaName: string) {
    return !!this.dashBacteria.top35.find(x => x.bacteria === bacteriaName);

  }
}
