import {Component, OnInit} from '@angular/core';
import {HumanButtonsComponent} from '../human-buttons/human-buttons.component';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-human',
  templateUrl: './human.component.html',
  styleUrls: ['./human.component.css']
})
export class HumanComponent implements OnInit {

  constructor(private translate: TranslateService) {
    this.updateInformation();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateInformation();
    });
  }

  ngOnInit() {
  }

  baseHuman = [
    {'id': 1, 'url': 'relationship', 'alias': 'relationship', 'name': 'INFORMATION_HUMAN.THE_RELATIONSHIP_BETWEEN_TITLE'},
    {'id': 2, 'url': 'testing-procedures', 'alias': 'procedures', 'name': 'INFORMATION_HUMAN.BUTTONS.TESTING'},
    {'id': 3, 'url': 'laboratories', 'alias': 'laboratories', 'name': 'INFORMATION_HUMAN.BUTTONS.LAB'},
    {'id': 4, 'url': 'health-and-diseases', 'alias': 'diseases', 'name': 'INFORMATION_HUMAN.BUTTONS.HEALTH'},
    {'id': 5, 'url': 'physical-exercise', 'alias': 'sports', 'name': 'INFORMATION_HUMAN.BUTTONS.FITNESS'}
  ]

  human = [
    {'id': 1, 'url': 'relationship', 'alias': 'relationship', 'name': 'The relationship between your diet & your microbiome'},
    {'id': 2, 'url': 'testing-procedures', 'alias': 'procedures', 'name': 'Testing procedures'},
    {'id': 3, 'url': 'laboratories', 'alias': 'laboratories', 'name': 'Laboratories'},
    {'id': 4, 'url': 'health-and-diseases', 'alias': 'diseases', 'name': 'Diseases'},
    {'id': 5, 'url': 'physical-exercise', 'alias': 'sports', 'name': 'Sports'}
  ]

  updateInformation() {
    let i = 0;
    for (const step of this.baseHuman) {
      this.translate.get(step.name).subscribe((text: string) => {
        this.human[i].name = text;
      });
      i++;
    }
  }

}
